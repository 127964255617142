.landing-logo
{
    height: 13vh;
    width: 13vw;
}

.landing-toolbar
{
    --ion-background-color: var(--ion-color-darkbg) !important;
    z-index: 99;
}
.landing-row
{
    min-height: 70vh;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    flex-direction: row;
}
.landing-title-text
{
    font-size: 1.4em;

    font-weight: 700;
    line-height:1.4em;
    color: white;
    font-family: 'Rubik', sans-serif !important;
    margin: .9375em 0 .46875em;
}


.landing-label
{
    font-size: 0.7em;
}
.landing-subtitle-text
{
    color: #cec7da;
    font-family: 'Rubik', sans-serif !important;
    font-size: 1em;
    line-height: 1.4em;
}

.landing-image-box-title
{
    font-size: 1em;

    font-weight: 700;
    line-height:1.4em;
    color: white;
    font-family: 'Rubik', sans-serif !important;
    margin: .9375em 0 .46875em;
}
.landing-image-box-text
{
    font-size: 0.9em;

    font-weight: 300;
    line-height:1.4em;
    color: white;
    font-family: 'Rubik', sans-serif !important;
    margin: .9375em 0 .46875em;
}

.landing-image-box-avatar
{
    position: relative;
    top:1px;
    right: 1px;
    overflow: visible!important;
    margin-left: auto;
    margin-right: auto;
}


.landing-image-box-badge{
    background-color: #E03E01;
    position: absolute;
    bottom: -3px;
    left: -3px;
    width: 20px;
    border-radius: 100%;
}


.landing-play-game-button
{
    width:100%;
    margin-top:  1.1vmax;
    font-weight: 600;
}

.landing-bold
{
    font-weight: 800;
}


.background-dark-tint
{
    background-color: var(--ion-color-darkbgtint);
    
}

.background-dark
{
    background-color: var(--ion-color-darkbg);
}

.max-width-900 {
    max-width: 56.25em;
    margin-left: auto;
    margin-right: auto;
    padding-left: 4.375em;
    padding-right:3.475em;
    padding-top:5em;
    padding-bottom:5em;
}

.landing-center-border
{
    border-top: 1px solid #282431;
}

#onramper-widget {
    height: 100%;
    width: 100%;
    margin: 0;
  }

@media only screen and (max-width: 600px) 
{

    .landing-logo
    {
        height: 12vmax;
        width: 30vmax;
    }

    .max-width-900 {
        max-width: 100vw;
        margin-left: auto;
        margin-right: auto;
        padding-left: 1em;
        padding-right: 1em;
        padding-top:5em;
        padding-bottom:5em;
    }

    .landing-title-text
    {
        font-size: 1.2em;

        font-weight: 700;
        line-height:1.4em;
        color: white;
        font-family: 'Rubik', sans-serif !important;
        margin: .9375em 0 .46875em;
    }


    .landing-subtitle-text
    {
        color: #cec7da;
        font-family: 'Rubik', sans-serif !important;
        font-size: 1em;
        line-height: 1em;
    }

    .landing-play-game-button
    {
        width:100%;
        margin-top:  1.1vmax;
        font-weight: 600;
    }

}
