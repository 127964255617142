.background-default
{
    background-color: #231f2b;
}

.about-title
{
    font-size: 1.75em;
    font-weight: 700;
    line-height: 1.4em;
    color: white;
    font-family: 'Rubik', sans-serif !important;
    margin: .9375em 0 .46875em;
}

.about-text
{
    color: #cec7da;
    font-family: 'Rubik', sans-serif !important;
}

.about-image
{
    width: 5em;
    height: 5em;
}

.about-margin-top
{
    margin-top: 10px;
    margin-bottom: 0px;
}

.about-name
{
    font-size: 1.5em;
    font-weight: 700;
    color: white;
    font-family: 'Rubik', sans-serif !important;
}

.about-quote
{
    padding-left: 4.375em;
}

.about-quote::before
{
    content: "“";
    position: absolute;
    top: 12%;
    left: -.15em;
    display: block;
    color: #343142;
    font-size: 12.5em;
    font-family: "Georgia", serif;
}

.large-margin-top
{
    margin-top: 5vh;
}

.info-create-account-button
{
    font-weight: 800;
    height: 4em;
}

.info-button-title
{
    font-size: 1.2em;
    font-weight: 800;
}

.info-item
{
    --background: rgba(240, 248, 255, 0);
    --border-color: #353143;
}

.info-button-subtitle
{
    font-weight: 200;
    font-size: 0.8em;

}

.info-half-row
{
    min-height: 35vh;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    flex-direction: row;
}

.info-card-title
{
    font-size: 1em;
    font-weight: 700;
    color: white;
}
.info-card
{
    border-width: 1px;
    border-style: solid;
    border-color: #353143;
    font-size: 0.9em;
}

.info-card-blank
{
    border-width: 1px;
    border-style: solid;
    border-color: #353143;
    font-size: 0.9em;
    height: 60vh;
}