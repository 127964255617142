.footer-background
{
    --ion-background-color: #1e1a25;
}

.footer-list-header
{
    font-size: .9375em;
    font-weight: 700;
    color: white;
}

.footer-list-text
{
    --background-activated-opacity: none;
    color: #cfc6da;
    --color-hover: white;
    font-size: 14px;
    transition: none;
}

.footer-list-text:hover
{
    --background-hover: none;
    margin-left: 0px;
}

.footer-list-text:active
{
    --background-activated: none;
}

.max-width-1400
{
    max-width: 1400px;
}

.email-input
{
    max-width: 31.25em;
    background-color: #292531;
    border-radius: .4375em;
    margin-top: .625em;
    margin-bottom: 3.125em;
}

.button-input
{
    --background: #1e1a25;
    border-radius: .4375em;
    position: absolute;
    right: 0px;
}

.button-input:hover
{
    --background: #0070ff;
}

.footer-icon
{
    color: #79767d;
    margin: 0.400em;
    padding: .3125em 0;
    font-size: 24px;
}

.xarcade-logo
{
    width: 2.5em;
    position: absolute;
    left: 70px;
}

.list-margin
{
    flex: 0 0 250px;
}

.small-margin
{
    margin-top: 3vh;
}

.large-margin
{
    margin-top: 5vh;
}


@media only screen and (max-width: 600px) 
{

    .xarcade-logo
    {

        display: none;
    }
}