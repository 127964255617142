#split-pane
{
    --side-max-width:10vw;
    --max-width: 80vw;
    --ion-background-color: var(--ion-color-darkbg);
    --border: none;
}



.home-tab-item
{
    color: white;
    --color-hover: rgb(255, 255, 255) !important;
    transition: 0.4s;
}

.home-tab-active
{
    --ion-background-color: rgba(228, 228, 228, 0.11);
    margin-left: 20px;

}


.home-tab-item:hover
{
    margin-left: 10px;
}

.header-mouse-over
{
    font-family: Rubik, sans-serif;
}

.mouse-over-title-text
{
    margin-left: 15px;
    font-size: 0.95em !important;
    font-weight: 600 ;
    color: #292531;
}

.mouse-over-title-subtext
{
    font-size: 80%;
    font-weight: 300;
    color: #87828e;
}

.sc-ion-popover-md-h
{
    --backdrop-opacity: var(--ion-backdrop-opacity, 0) !important;
}