#home-content
{
    --ion-background-color: var(--ion-color-darkbgtint);
    --background: linear-gradient(rgba(102, 7, 255, 0) 10%,var(--ion-color-darkbgtint) 65%), url(../assets/bg/bg-universal-2.jpg)  no-repeat center center / cover !important;
}

.home-toolbar
{
    --background: rgba(255, 255, 255, 0);
    padding-left: 32px;
    margin-top: 16px;
}

.huge-margin
{
    margin-top: 9vh;
}

.white-text
{
    color: white;
}

.large-text
{
    font-size: 3.5vmax;
    font-weight: 900;
}


.main-card
{
    --ion-background-color: var(--ion-color-darkbg);
    color: white;
}

.game-img
{
    height: 30vmin;
    object-fit: cover;
    transition: all 0.3s;
}

.game-img:hover
{
    transform: scale(1.1);
}

.game-badge
{
    position:absolute;
    height: 9vmin;
    top: 25vmin;
    right: -20vmin; 
}

.credits
{
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.copyright
{
   font-size: 10px; 
}
.proximax
{
    height: 20px;
}

.main-grid
{
    padding: 16px;
}



@media only screen and (max-width: 600px) 
{
    .main-grid
    {
        padding: 0px;
        text-align: center;
    }
    .main-card
    {
        padding: 0px !important;
    }
    .main-card-content
    {
        padding: 0px 0px 0px 0px;
    }
    .copyright
    {
       font-size: 5px; 
    }

    .large-text
    {
        font-size: 4vmax;
        font-weight: 900;
    }
    .home-toolbar
    {
        --background: rgba(255, 255, 255, 0);
        padding-left: 6px;
        margin-top: 16px;
    }

}