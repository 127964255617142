.welcome-modal {
    --ion-background-color: #2e2c31;
    color: white;
}

.modal-wrapper {
    height: 50% !important;
    width: 30% !important;
    border-radius: 10px !important;
}

.modal-size {
    height: 80%;
    width: 80%;
}

.ion-page {
    display: inline-block !important;
}

.center-col {
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-welcome {
    height: 150px;
    width: 150px;
    object-fit: cover;
    display: block;
    border-radius: 5px !important;
    background-color: black;
}

.invitation-text {
    font-size: 16px;
}

.decline-btn {
    --background: #2e2c31;
}

.mb-0 {
    margin-bottom: 0px;
}

.mt-0 {
    margin-top: 0px;
}

.mt-50 {
    margin-top: 50px;
}

.centered {
    text-align: center;
}