.background
{
    background-color: #2B292C;
    height: 250px;
}

.text-gray
{
    color: #AFAFAF;
}

.game-page-text
{
    color: white;
}

.game-title
{
    font-size: 36px;
    font-weight: 500;
}

.game-border
{
    border-bottom: 1px solid #2E2B2F;
}

.game-subtitle
{
    font-size: 24px;
    font-weight: 500;
}

.game-headingtitle
{
    font-size: 18px;
    font-weight: 600;
    color:#AFAFAF;
}

.game-subheadingtitle
{
    font-size: 14px;
    font-weight: 500;
    color:#AFAFAF;
}

.game-img-slides
{
    height: 200px !important;
    object-fit: cover;
}

.game-text
{
    color: #AFAFAF;
    font-size: 18px;
}

.icons-align
{
    display: inline-block;
    vertical-align: middle;
}

.p50
{
    padding-left: 50px !important;
    padding-right: 50px !important;
}

ion-slides
{
    height: 200px;
}

ion-content
{
    --ion-background-color: #262526;
}
