.auth-qr
{
    height: 80%;
}
ion-icon {
    color: var(--ion-color-primary);
  }

.grid-contain
{
    width: 30vw;
}

.right-grid-contain
{
    width: 40vw;
}

.fullHeight
{
    min-height: 100vh;
}

.leftCol
{
    background: rgb(23,8,49);
    background: linear-gradient(144deg, rgba(23,8,49,1) 0%, rgba(18,21,92,1) 35%, rgba(8,80,132,1) 100%);
    color: white;
}

.leftCol-login
{
    background: #292531;
    color: white;
}

.rightCol
{
    background-color: white;
}

.logo-padding
{
    padding-top: 10vh;
}

.logo
{
    height:10vh;

}

.back-to-home
{
    color: #000000;
}

.back-to-home-footer
{
    position: absolute;
    bottom: 60px;
}



.tiny-margin
{
    margin-top: 0.7vh;
}

.small-margin
{
    margin-top: 2vh;
}

.large-margin
{
    margin-top: 5vh;

}

.huge-margin
{
    margin-top: 9vh;
}

.tiny-left-margin
{
    margin-left: 2vh;
}

.welcome-back-text
{
    padding-top: 3vh;
    font-weight: 600 !important;  
    font-size:24px;
    color: black;

}

.bordered-input
{
    --background: white;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 400;
    color: #000;
    width: 100%;
}

.checkbox-input
{
    font-size: 18px;
    font-weight: 400;
    color: #000;
    width: 100%;
}

.background-white {
    --background: white;
}


.form-button
{
    border: 0;
    border-radius: 5px;
    background-color: #0070FF;
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.4em;
    display: inline-block;
    width: 100%;
}


.link
{
    color: var(--ion-color-primary) !important;
    text-decoration: underline;
}

.create-account
{
    font-size: 20px !important;
}

.footer
{
    font-size: 11px;
    position: absolute;
    bottom: 30px;
}

.desc
{
    font-size: 36px;
}

.desc-half
{
    font-size: 18px;
}

.square-bits-scattered
{
    position: fixed;
    top: 175px;
    left: 675px;
}

.reg-page-pc
{
    position: absolute;
    bottom: 0px;
    left: -540px;
}

.blue-line-login
{
    position: absolute;
    bottom: 130px;
}


@media only screen and (max-width: 600px) 
{
    .logo-padding
    {
        padding-top: 1vh;
    }

    .grid-contain
    {
        width: 80vw;
    }

    .right-grid-contain
    {
        width: 90vw;
    }

    .desc
    {
        font-size: 18px;
    }

    .large-margin
    {
        margin-top: 1.5vh !important;
    }

    .huge-margin
    {
        margin-top: 2vh;
    }

    .bottom-margin
    {
        margin-bottom: 2vh;
    }

    .footer
    {
        text-align: left !important;
        font-size: 9px;
        position: absolute;
        bottom: 18px;
    }

    .sm-font
    {
        font-size: 14px;
    }

    .back-to-home
    {
        color: #FFFFFF;
        margin-left: 75px;
    }

    .square-bits-lined
    {
        position: absolute;
        top: -15px;
        right: -18px;
        height: auto;
        width: auto;
        max-height: 50px;
        max-width: 150px;
    }

    .welcome-back-text
    {
        padding-top: 3vh;
        font-weight: 600 !important;  
        font-size: 18px;
    }

    .leftCol-login
    {
        background: rgb(23,8,49);
        background: linear-gradient(144deg, rgba(23,8,49,1) 0%, rgba(18,21,92,1) 35%, rgba(8,80,132,1) 100%);
        color: white;
    }

    .reg-page-pc
    {
        display: none;
    }
}

