.header-toolbar
{
    --ion-background-color: var(--ion-color-darkbg) !important;
    z-index: 1;
}

.header-logo
{
    height: 50px;
}

.footer-logo
{
    height: 25px;
}

.footer-text
{
    font-size: 12px;
}

.wallet-modal
{
    --ion-background-color: var(--ion-color-darkbg) !important;
    z-index: 2;
}