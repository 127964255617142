@import url(https://fonts.googleapis.com/css2?family=Hind+Vadodara:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,700;1,300;1,400&display=swap);
.auth-qr
{
    height: 80%;
}
ion-icon {
    color: var(--ion-color-primary);
  }

.grid-contain
{
    width: 30vw;
}

.right-grid-contain
{
    width: 40vw;
}

.fullHeight
{
    min-height: 100vh;
}

.leftCol
{
    background: rgb(23,8,49);
    background: linear-gradient(144deg, rgba(23,8,49,1) 0%, rgba(18,21,92,1) 35%, rgba(8,80,132,1) 100%);
    color: white;
}

.leftCol-login
{
    background: #292531;
    color: white;
}

.rightCol
{
    background-color: white;
}

.logo-padding
{
    padding-top: 10vh;
}

.logo
{
    height:10vh;

}

.back-to-home
{
    color: #000000;
}

.back-to-home-footer
{
    position: absolute;
    bottom: 60px;
}



.tiny-margin
{
    margin-top: 0.7vh;
}

.small-margin
{
    margin-top: 2vh;
}

.large-margin
{
    margin-top: 5vh;

}

.huge-margin
{
    margin-top: 9vh;
}

.tiny-left-margin
{
    margin-left: 2vh;
}

.welcome-back-text
{
    padding-top: 3vh;
    font-weight: 600 !important;  
    font-size:24px;
    color: black;

}

.bordered-input
{
    --background: white;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 400;
    color: #000;
    width: 100%;
}

.checkbox-input
{
    font-size: 18px;
    font-weight: 400;
    color: #000;
    width: 100%;
}

.background-white {
    --background: white;
}


.form-button
{
    border: 0;
    border-radius: 5px;
    background-color: #0070FF;
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.4em;
    display: inline-block;
    width: 100%;
}


.link
{
    color: var(--ion-color-primary) !important;
    text-decoration: underline;
}

.create-account
{
    font-size: 20px !important;
}

.footer
{
    font-size: 11px;
    position: absolute;
    bottom: 30px;
}

.desc
{
    font-size: 36px;
}

.desc-half
{
    font-size: 18px;
}

.square-bits-scattered
{
    position: fixed;
    top: 175px;
    left: 675px;
}

.reg-page-pc
{
    position: absolute;
    bottom: 0px;
    left: -540px;
}

.blue-line-login
{
    position: absolute;
    bottom: 130px;
}


@media only screen and (max-width: 600px) 
{
    .logo-padding
    {
        padding-top: 1vh;
    }

    .grid-contain
    {
        width: 80vw;
    }

    .right-grid-contain
    {
        width: 90vw;
    }

    .desc
    {
        font-size: 18px;
    }

    .large-margin
    {
        margin-top: 1.5vh !important;
    }

    .huge-margin
    {
        margin-top: 2vh;
    }

    .bottom-margin
    {
        margin-bottom: 2vh;
    }

    .footer
    {
        text-align: left !important;
        font-size: 9px;
        position: absolute;
        bottom: 18px;
    }

    .sm-font
    {
        font-size: 14px;
    }

    .back-to-home
    {
        color: #FFFFFF;
        margin-left: 75px;
    }

    .square-bits-lined
    {
        position: absolute;
        top: -15px;
        right: -18px;
        height: auto;
        width: auto;
        max-height: 50px;
        max-width: 150px;
    }

    .welcome-back-text
    {
        padding-top: 3vh;
        font-weight: 600 !important;  
        font-size: 18px;
    }

    .leftCol-login
    {
        background: rgb(23,8,49);
        background: linear-gradient(144deg, rgba(23,8,49,1) 0%, rgba(18,21,92,1) 35%, rgba(8,80,132,1) 100%);
        color: white;
    }

    .reg-page-pc
    {
        display: none;
    }
}


.landing-logo
{
    height: 13vh;
    width: 13vw;
}

.landing-toolbar
{
    --ion-background-color: var(--ion-color-darkbg) !important;
    z-index: 99;
}
.landing-row
{
    min-height: 70vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-content: center;
            align-content: center;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.landing-title-text
{
    font-size: 1.4em;

    font-weight: 700;
    line-height:1.4em;
    color: white;
    font-family: 'Rubik', sans-serif !important;
    margin: .9375em 0 .46875em;
}


.landing-label
{
    font-size: 0.7em;
}
.landing-subtitle-text
{
    color: #cec7da;
    font-family: 'Rubik', sans-serif !important;
    font-size: 1em;
    line-height: 1.4em;
}

.landing-image-box-title
{
    font-size: 1em;

    font-weight: 700;
    line-height:1.4em;
    color: white;
    font-family: 'Rubik', sans-serif !important;
    margin: .9375em 0 .46875em;
}
.landing-image-box-text
{
    font-size: 0.9em;

    font-weight: 300;
    line-height:1.4em;
    color: white;
    font-family: 'Rubik', sans-serif !important;
    margin: .9375em 0 .46875em;
}

.landing-image-box-avatar
{
    position: relative;
    top:1px;
    right: 1px;
    overflow: visible!important;
    margin-left: auto;
    margin-right: auto;
}


.landing-image-box-badge{
    background-color: #E03E01;
    position: absolute;
    bottom: -3px;
    left: -3px;
    width: 20px;
    border-radius: 100%;
}


.landing-play-game-button
{
    width:100%;
    margin-top:  1.1vmax;
    font-weight: 600;
}

.landing-bold
{
    font-weight: 800;
}


.background-dark-tint
{
    background-color: var(--ion-color-darkbgtint);
    
}

.background-dark
{
    background-color: var(--ion-color-darkbg);
}

.max-width-900 {
    max-width: 56.25em;
    margin-left: auto;
    margin-right: auto;
    padding-left: 4.375em;
    padding-right:3.475em;
    padding-top:5em;
    padding-bottom:5em;
}

.landing-center-border
{
    border-top: 1px solid #282431;
}

#onramper-widget {
    height: 100%;
    width: 100%;
    margin: 0;
  }

@media only screen and (max-width: 600px) 
{

    .landing-logo
    {
        height: 12vmax;
        width: 30vmax;
    }

    .max-width-900 {
        max-width: 100vw;
        margin-left: auto;
        margin-right: auto;
        padding-left: 1em;
        padding-right: 1em;
        padding-top:5em;
        padding-bottom:5em;
    }

    .landing-title-text
    {
        font-size: 1.2em;

        font-weight: 700;
        line-height:1.4em;
        color: white;
        font-family: 'Rubik', sans-serif !important;
        margin: .9375em 0 .46875em;
    }


    .landing-subtitle-text
    {
        color: #cec7da;
        font-family: 'Rubik', sans-serif !important;
        font-size: 1em;
        line-height: 1em;
    }

    .landing-play-game-button
    {
        width:100%;
        margin-top:  1.1vmax;
        font-weight: 600;
    }

}

#split-pane
{
    --side-max-width:10vw;
    --max-width: 80vw;
    --ion-background-color: var(--ion-color-darkbg);
    --border: none;
}



.home-tab-item
{
    color: white;
    --color-hover: rgb(255, 255, 255) !important;
    transition: 0.4s;
}

.home-tab-active
{
    --ion-background-color: rgba(228, 228, 228, 0.11);
    margin-left: 20px;

}


.home-tab-item:hover
{
    margin-left: 10px;
}

.header-mouse-over
{
    font-family: Rubik, sans-serif;
}

.mouse-over-title-text
{
    margin-left: 15px;
    font-size: 0.95em !important;
    font-weight: 600 ;
    color: #292531;
}

.mouse-over-title-subtext
{
    font-size: 80%;
    font-weight: 300;
    color: #87828e;
}

.sc-ion-popover-md-h
{
    --backdrop-opacity: var(--ion-backdrop-opacity, 0) !important;
}
.footer-background
{
    --ion-background-color: #1e1a25;
}

.footer-list-header
{
    font-size: .9375em;
    font-weight: 700;
    color: white;
}

.footer-list-text
{
    --background-activated-opacity: none;
    color: #cfc6da;
    --color-hover: white;
    font-size: 14px;
    transition: none;
}

.footer-list-text:hover
{
    --background-hover: none;
    margin-left: 0px;
}

.footer-list-text:active
{
    --background-activated: none;
}

.max-width-1400
{
    max-width: 1400px;
}

.email-input
{
    max-width: 31.25em;
    background-color: #292531;
    border-radius: .4375em;
    margin-top: .625em;
    margin-bottom: 3.125em;
}

.button-input
{
    --background: #1e1a25;
    border-radius: .4375em;
    position: absolute;
    right: 0px;
}

.button-input:hover
{
    --background: #0070ff;
}

.footer-icon
{
    color: #79767d;
    margin: 0.400em;
    padding: .3125em 0;
    font-size: 24px;
}

.xarcade-logo
{
    width: 2.5em;
    position: absolute;
    left: 70px;
}

.list-margin
{
    -webkit-flex: 0 0 250px;
            flex: 0 0 250px;
}

.small-margin
{
    margin-top: 3vh;
}

.large-margin
{
    margin-top: 5vh;
}


@media only screen and (max-width: 600px) 
{

    .xarcade-logo
    {

        display: none;
    }
}
#home-content
{
    --ion-background-color: var(--ion-color-darkbgtint);
    --background: linear-gradient(rgba(102, 7, 255, 0) 10%,var(--ion-color-darkbgtint) 65%), url(/static/media/bg-universal-2.3d330799.jpg)  no-repeat center center / cover !important;
}

.home-toolbar
{
    --background: rgba(255, 255, 255, 0);
    padding-left: 32px;
    margin-top: 16px;
}

.huge-margin
{
    margin-top: 9vh;
}

.white-text
{
    color: white;
}

.large-text
{
    font-size: 3.5vmax;
    font-weight: 900;
}


.main-card
{
    --ion-background-color: var(--ion-color-darkbg);
    color: white;
}

.game-img
{
    height: 30vmin;
    object-fit: cover;
    transition: all 0.3s;
}

.game-img:hover
{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.game-badge
{
    position:absolute;
    height: 9vmin;
    top: 25vmin;
    right: -20vmin; 
}

.credits
{
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.copyright
{
   font-size: 10px; 
}
.proximax
{
    height: 20px;
}

.main-grid
{
    padding: 16px;
}



@media only screen and (max-width: 600px) 
{
    .main-grid
    {
        padding: 0px;
        text-align: center;
    }
    .main-card
    {
        padding: 0px !important;
    }
    .main-card-content
    {
        padding: 0px 0px 0px 0px;
    }
    .copyright
    {
       font-size: 5px; 
    }

    .large-text
    {
        font-size: 4vmax;
        font-weight: 900;
    }
    .home-toolbar
    {
        --background: rgba(255, 255, 255, 0);
        padding-left: 6px;
        margin-top: 16px;
    }

}
.header-toolbar
{
    --ion-background-color: var(--ion-color-darkbg) !important;
    z-index: 1;
}

.header-logo
{
    height: 50px;
}

.footer-logo
{
    height: 25px;
}

.footer-text
{
    font-size: 12px;
}

.wallet-modal
{
    --ion-background-color: var(--ion-color-darkbg) !important;
    z-index: 2;
}
.background-default
{
    background-color: #231f2b;
}

.about-title
{
    font-size: 1.75em;
    font-weight: 700;
    line-height: 1.4em;
    color: white;
    font-family: 'Rubik', sans-serif !important;
    margin: .9375em 0 .46875em;
}

.about-text
{
    color: #cec7da;
    font-family: 'Rubik', sans-serif !important;
}

.about-image
{
    width: 5em;
    height: 5em;
}

.about-margin-top
{
    margin-top: 10px;
    margin-bottom: 0px;
}

.about-name
{
    font-size: 1.5em;
    font-weight: 700;
    color: white;
    font-family: 'Rubik', sans-serif !important;
}

.about-quote
{
    padding-left: 4.375em;
}

.about-quote::before
{
    content: "“";
    position: absolute;
    top: 12%;
    left: -.15em;
    display: block;
    color: #343142;
    font-size: 12.5em;
    font-family: "Georgia", serif;
}

.large-margin-top
{
    margin-top: 5vh;
}

.info-create-account-button
{
    font-weight: 800;
    height: 4em;
}

.info-button-title
{
    font-size: 1.2em;
    font-weight: 800;
}

.info-item
{
    --background: rgba(240, 248, 255, 0);
    --border-color: #353143;
}

.info-button-subtitle
{
    font-weight: 200;
    font-size: 0.8em;

}

.info-half-row
{
    min-height: 35vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-content: center;
            align-content: center;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.info-card-title
{
    font-size: 1em;
    font-weight: 700;
    color: white;
}
.info-card
{
    border-width: 1px;
    border-style: solid;
    border-color: #353143;
    font-size: 0.9em;
}

.info-card-blank
{
    border-width: 1px;
    border-style: solid;
    border-color: #353143;
    font-size: 0.9em;
    height: 60vh;
}
.background
{
    background-color: #2B292C;
    height: 250px;
}

.text-gray
{
    color: #AFAFAF;
}

.game-page-text
{
    color: white;
}

.game-title
{
    font-size: 36px;
    font-weight: 500;
}

.game-border
{
    border-bottom: 1px solid #2E2B2F;
}

.game-subtitle
{
    font-size: 24px;
    font-weight: 500;
}

.game-headingtitle
{
    font-size: 18px;
    font-weight: 600;
    color:#AFAFAF;
}

.game-subheadingtitle
{
    font-size: 14px;
    font-weight: 500;
    color:#AFAFAF;
}

.game-img-slides
{
    height: 200px !important;
    object-fit: cover;
}

.game-text
{
    color: #AFAFAF;
    font-size: 18px;
}

.icons-align
{
    display: inline-block;
    vertical-align: middle;
}

.p50
{
    padding-left: 50px !important;
    padding-right: 50px !important;
}

ion-slides
{
    height: 200px;
}

ion-content
{
    --ion-background-color: #262526;
}

.welcome-modal {
    --ion-background-color: #2e2c31;
    color: white;
}

.modal-wrapper {
    height: 50% !important;
    width: 30% !important;
    border-radius: 10px !important;
}

.modal-size {
    height: 80%;
    width: 80%;
}

.ion-page {
    display: inline-block !important;
}

.center-col {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.img-welcome {
    height: 150px;
    width: 150px;
    object-fit: cover;
    display: block;
    border-radius: 5px !important;
    background-color: black;
}

.invitation-text {
    font-size: 16px;
}

.decline-btn {
    --background: #2e2c31;
}

.mb-0 {
    margin-bottom: 0px;
}

.mt-0 {
    margin-top: 0px;
}

.mt-50 {
    margin-top: 50px;
}

.centered {
    text-align: center;
}
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #0070FF;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0462dd;
  --ion-color-primary-tint: #2381fc;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-darkbg : #292531;
  --ion-color-darkbgtint: #1E1B24;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #5268C2;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3e54aa;
    --ion-color-primary-tint: #637ad6;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    --ion-color-darkbg : #292531;
    --ion-color-darkbgtint: #1E1B24;

    --ion-color-comingsoon: #E13F04;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #161820;
    --ion-background-color-rgb: 22,24,32;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1E222D;

    --ion-toolbar-background: #1E222D;

    --ion-tab-bar-background: #1E222D;

    --ion-card-background: #1E222D;
  }
}
*:not(ion-icon) {
  font-family: 'Hind Vadodara', sans-serif!important;
}





ion-button { text-transform: none; }
